/* ------------------------------------------ Ready and Init Functions ------------------------------ */

// init Functions
function initUTCOffsetCookie(){
	const date = new Date();
	const offset = date.getTimezoneOffset();
	$.cookie("UTCOFFSET",offset);
}

function initInjectMissingTokens() {
	$inp = $("<input />").attr({
		type: "hidden",
		name: "token",
		value: g.csrfToken
	});

	$("form:not(:has(input[name=token]))").prepend( $inp );
}

/**
 * Set the left menu for open/closed/automatic mode
 *
 * @param {String} type - menuAutomatic, menuClosed, menuOpen
 * @return {void} - fire off init and update methods to act on the new setting
 */
function modifyMenu(type) {
	$("body").attr('id', type);
	initTableScrolling();
	updateClientSetting('MENU_DISPLAY', type);
	initPageTop();
}

/**
 * Sets the global table size
 *
 * @param {String} type - regularFormat, mediumFormat, smallFormat
 * @return void - attaches the type name as a top level class effecting all tables
 */
function modifyTableSize(type) {
	$("body").removeClass();
	$("body").addClass(type);
	updateClientSetting('TABLE_DISPLAY', type);
}

/**
 * Sets the table sorting functionality
 *
 * @param {String} type - individual, grouped
 * @return void - attaches the type name as a top level class effecting all tables
 */
function modifyTableSorting(type) {
	// set data setting
	g.clientSettingsObj.tableSorting = type;
	// update client
	updateClientSetting('TABLE_SORTING', type);
	// reload page w/purposeful timeout to allow client setting to update
	setTimeout(() => {
		location.reload();
	}, 500);
}

/**
 * Sets the table sorting functionality
 *
 * @param {String} state - true, false
 * @return void - save and reload the page
 */
function modifyReportHeaderWrapping(state) {
	// set data setting
	g.clientSettingsObj.reportHeaderWrapping = state;
	console.log('modifyReportHeaderWrapping(), REPORT_HEADER_WRAPPING, state = ', state);
	// update client
	updateClientSetting('REPORT_HEADER_WRAPPING', state);
	// reload page w/purposeful timeout to allow client setting to update
	setTimeout(() => {
		location.reload();
	}, 500);
}

/**
 * Sets the item selection functionality
 *
 * @param {String} type - checkbox, list
 * @return void
 */
function modifyItemSelection(type) {
	// set data setting
	g.clientSettingsObj.itemSelection = type;
	// update client
	updateClientSetting('ITEM_SELECTION', type);
	// reload page w/purposeful timeout to allow client setting to update
	setTimeout(() => {
		location.reload();
	}, 500);
}

/**
 * Sets the item selection ordering
 *
 * @param {String} type - alphabetical, mostrecent
 * @return void
 */
function modifyItemOrdering(type) {
	// set data setting
	g.clientSettingsObj.itemOrdering = type;
	// update client
	updateClientSetting('ITEM_ORDERING', type);
	// reload page w/purposeful timeout to allow client setting to update
	setTimeout(() => {
		location.reload();
	}, 500);
}

/**
 * Sets the user selection functionality
 *
 * @param {String} type - supervisor, alphabetical
 * @return void
 */
function modifyUserSelection(type) {
	// set data setting
	g.clientSettingsObj.userSelection = type;
	// update client
	updateClientSetting('USER_SELECTION', type);
	// reload page w/purposeful timeout to allow client setting to update
	setTimeout(() => {
		location.reload();
	}, 500);
}

/**
 * Sets the user supervisor grouping selection functionality
 *
 * @param {String} type - supervisor, alphabetical
 * @return void
 */
function modifyUserSelectionGrouping(type) {
	// set data setting
	g.clientSettingsObj.userSelectionGrouping = type;
	// update client
	updateClientSetting('USER_SELECTION_GROUPING', type);
	// reload page w/purposeful timeout to allow client setting to update
	setTimeout(() => {
		location.reload();
	}, 500);
}

/**
 * Sets whether the guided tours launcher is enabled
 *
 * @param {String} type - show, hide
 * @return void
 */
function modifyGuidedToursSelection(type) {
	// set data setting
	g.clientSettingsObj.guidedTours = type;
	// update client
	updateClientSetting('GUIDED_TOURS', type);
	// reload page w/purposeful timeout to allow client setting to update
	setTimeout(() => {
		location.reload();
	}, 500);
}

/* ------------------------------------------ Functions Notifications ------------------------------ */

// Injects base handlers
function initStylesAnimations() {
	var w, wi, hash;

	// set all zebra stripes on page load for some older IE's
	if (g.isIEVerLTE8) setZebraStripes(true);
	onResizeScrollAction();
	g.lastScrollPosition = $("body").scrollTop();

	$(window)
		.on("resize scroll",function(event) {
			//console.log(g.onResizeScrollArray.length);
			if (typeof event.originalEvent !== "undefined") {
				if (event.type == "scroll") {
					if (g.preventScroll)
						$("body").scrollTop(g.lastScrollPosition);
					g.lastScrollPosition = $("body").scrollTop();
				}
				if (g.onResizeScrollID)
					clearTimeout(g.onResizeScrollID);
				g.onResizeScrollID = setTimeout($.proxy(function(){
					// default actions
					onResizeScrollAction();
					if (event.type == "resize") {
						resizeAllMaps();
						resetOverflows();
					}
				},this),500);
				if (g.onResizeScrollArrayID)
					clearTimeout(g.onResizeScrollArrayID);
				if (g.onResizeScrollArray.length > 0) {
					g.onResizeScrollArrayID = setTimeout(function(){
						// if funcs have been added to onResizeScrollArray, loop through and call them
						for (n=0;n<g.onResizeScrollArray.length;n++) {
							if (typeof g.onResizeScrollArray[n] === "function")
								g.onResizeScrollArray[n].call(event);
						}
					},50);
				}
			}
		});

	// default type
	var displayType = g.clientSettingsObj.numberDisplayFormat;

	// toggle decimal and human
	$("body").on("click", ".toggleHumanDecimal", function () {

		$(".dataTable .toggleToHuman").toggleClass("hide");
		$(".dataTable .toggleToDecimal").toggleClass("hide");

		$("#estimate-matrix-container .toggleToHuman").toggleClass("hide");		// for the hourly timesheet top section Totals widget
		$("#estimate-matrix-container .toggleToDecimal").toggleClass("hide");

		$("#accrualBalancesTable .toggleToHuman").toggleClass("hide");		// for the hourly timesheet top section Balances widget
		$("#accrualBalancesTable .toggleToDecimal").toggleClass("hide");

		if ( displayType === 'decimal' ) {
			updateClientSetting('NUMBER_DISPLAY_FORMAT', 'human');
			$(".hourlyDisplay").removeClass("inline").addClass("hide");
			$(".decimalDisplay").removeClass("hide").addClass("inline");
			displayType = "human";
		} else {
			updateClientSetting('NUMBER_DISPLAY_FORMAT', 'decimal');
			$(".hourlyDisplay").removeClass("hide").addClass("inline");
			$(".decimalDisplay").removeClass("inline").addClass("hide");
			displayType = "decimal";
		}

	});

	// tablesorter
	$("table.dataTable")
		.on({
			click: function() {
				$(this)
					.closest("tr")
					.find("div.notesControl")
					.trigger("click");
			}
		},"td.workDescription")
		.on({
			mouseover: function() {
				$(this).prev("tr").toggleClass("hoverRow",true)
			},
			mouseout: function() {
				$(this).prev("tr").toggleClass("hoverRow",false)
			}
		},"tr.notes.expanded")

	// datatable
	// adds handlers for inputs and checkboxes and radio buttons
	$( "#pageWrapper" )
		.on({
			change: function(){
				if ($(this).is("[data-group]")) {
					var $sel = $("table.group"+$(this).attr("data-group")+" input[type=checkbox]:not(:disabled)");
					if ($(this).is(":checked"))
						$sel.filter(":not(:checked)").prop("checked",true);
					else
						$sel.filter(":checked").prop("checked",false);
				}
			}
		}, "input.checkAllInGroup" )
		.on({
			click: function(){
				// Select All Groups / Deselect All
				if ($(this).is("[data-group]")) {
					var $sel = $("table.group"+$(this).attr("data-group")+" input[type=checkbox]:not(:disabled)");
					let isChecked = true;
					let group = [];

					if ($(this).is(".on")){
						group = $sel.filter(":not(:checked)");
						isChecked = true;
						group.prop("checked",true);
					}
					else {
						isChecked = false;
						group = $sel.filter(":checked");
						group.prop("checked",false);
					}

					//  Active Users: select supervisors who report to the group just checked (e.g. check/uncheck lower level supervisors)
					if(group.length > 0 && group[0].name == "UserList" && g.clientSettingsObj.userSelection == "supervisor" && g.clientSettingsObj.userSelectionGrouping == "supervisor_full"){
						// console.log("group to loop through = ", group);
						for(var i = 0; i < group.length; i++){
							$("[name='" + group[i].name + "'][value='" + group[i].value + "']").prop("checked", isChecked);
						}
					}
				}
			}
		}, "a.selectAllInGroup" )
		.on({
			dblclick: function(){
				if ($.isNumeric($(this).val()))
					$(this).trigger("select");
			}
		}, "input[type=text]" )
		.on({
			change: function() {
				var on = $(this).is(":checked"),
					$inp = $(this).next("input[name=" + $(this).attr("data-name") + "]");
					$inp.val( on ? $(this).attr("data-checked-value") : $(this).attr("data-unchecked-value"));
			}
		}, "input[data-name][data-unchecked-value]:checkbox:not([disabled])" )

		// ---- "Check all" box in a table was checked----
		.on({
			change: function(){
				$(this)
					.closest("table")
					.find("tr > td > input:checkbox:not(:disabled)")
					.prop("checked",$(this).is(":checked"))
					.closest("table.expenseTable")
					.recalculateExpenseTotals();
			}
		}, "table.dataTable > thead > tr > * > div > input:checkbox.checkAll" )
		.on({
			change: function(){
				$(this).closest("table.expenseTable")
					.recalculateExpenseTotals();
			}
		}, "table.expenseTable tr > * > input:checkbox:not(.checkAll)" )

		// ---- checkbox and radio ----
		.on({
			click: function() {
				if (!$(this).is(":focus")){
					$(this).trigger("focus");
				}
			},
			focus: function() {
				var $theLabel = $("label[for="+$(this).attr("id")+"]")
				$theLabel.data("originalColor",$theLabel.css("color"));
				if ($theLabel.closest("th").length > 0 || $theLabel.is(".onDark")) {
					$theLabel
						.stop(true,true)
						.animate({backgroundColor:"#B0B000"},50)
						.animate({backgroundColor:"#7D6D00"},200);
				}
				else {
					$theLabel
					.stop(true,true);
					//  Commented out color change on clicking certain elements (e.g. checkboxes on user assignment page)
					//  handle in scss instead!
					// .animate({backgroundColor:"yellow"},50)
					// .animate({backgroundColor:"#FFFF99"},200);
				}
			},
			blur: function() {
				var $theLabel = $("label[for="+$(this).attr("id")+"]");
				$theLabel
					.stop(true,true)
					.css({backgroundColor:"transparent"});
			}
		}, "input:checkbox,input:radio" )
		.on({
			click: function(ev) {
				if ($(this).is("[data-disabled]")) {
					ev.preventDefault();
					ev.stopPropagation();
					ev.stopImmediatePropagation();
				}
			}
		}, "input[type=submit],input[type=button],button" );


	// set error confirmation
	$("div.errorMsg, div.confirmation").each(function(){
		if (!$(this).is(":hidden")) {
			$(this).animate({opacity:"1"}, 600);
		}
	});

	// insert explicit checkbox hidden inputs
	$("input[data-name][data-checked-value][data-unchecked-value]:checkbox:not([disabled])").each(function(){
		var $inp = $("<input />").attr({
			type: "hidden",
			name: $(this).attr("data-name"),
			value: $(this).is("[checked]") ? $(this).attr("data-checked-value") : $(this).attr("data-unchecked-value")
		});
		$(this).after($inp);
	});

	// add click handler for buttons which change the action of a form
	// (ex: use attr data-submit-action="save" to change the input named "action" to "save"
	$("form *[data-submit-action]").on("click",function(){
		var $f = $(this).closest("form"),
				v = $(this).attr("data-submit-action");
		$f.find("input[name=Action]")
			.val(v)
			.end()
			.trigger("submit");
	});

	// change table arrow direction
	$("table.showChangesInTable").showChangesInTable();
	$("table.alternateRowsByDate").each(function(){ $(this).alternateRowsByDate() });
	$("tr > .autoCell").on("click",function(event){
		if (event.target.nodeName !== "INPUT")
			$(this).autoCellClick();
	});

	// create ellipsis expander for divs with "more" class
	$(".more").each(function(){
		var maxChar = 80,
				str = $(this).html(),
				str1, str2;
		if (str.length > maxChar) {
			str1 = str.substr(0,maxChar);
			str2 = str.substr(maxChar,str.length - maxChar);
			$(this)
				.html(str1 + '<span class="moreLink">&hellip;&nbsp;<a href="javascript:void(0);">more</a></span><span class="moreContent" style="display:none;">' + str2 + '</span>')
				.find("span.moreLink")
				.on("click",function(){
					$(this)
						.siblings("span.moreContent")
						.show(250)
						.end()
						.hide();
				});
		}
	});

	/**
	 * Show or hide the left menu and toggle the "hamburger" icon
	 */
	$("#menuExpander").on("click",function(){
		var showSideMenu = $("#contentWrapper").is(".showSideMenu");
		$(".hamburger").toggleClass("is-active");
		$("#contentWrapper").toggleClass("showSideMenu",!showSideMenu);
		initPageTop();
	});

	// If any second level menus are open in compact mode we need to close them when loading the page
	$(document).ready(function() {
		if($("#sidemenu").hasClass("compact")){
			closeAllCompactSecondaryMenus()
		}
		// If you click off the compact menu dropdown handler it will close the dropdowns (not full menu dropdowns, though)
		$(document).on("click", function(event){
			if(!$(event.target).closest(".compact .dropdown-handler").length){
				closeAllCompactSecondaryMenus();
			}
		});
	});

	/**
	 * Toggle the left menu between compact (icon only) and full size (icon and text)
	 */
	$("#menuCompactor").on("click", function(){
		let rate = 500;
		$("#sidemenu").toggleClass("compact", rate);
		$("#sidemenu").toggleClass("standard", rate);
		$(".loginButton.clockInOutButton").toggleClass("compact", rate);
		$(".editShiftButton").toggleClass("compact", rate);
		$(".breakButton").toggleClass("compact", rate);
		$(".loginButton.btnDisabled").toggleClass("compact"); // we can't ease this one as it is already on a timer in page-clock.js
		$("#clock").toggleClass("compact", rate);
		$(".dropdown-handler").toggleClass("compact", rate);
		$(".compactorIcon").toggleClass("rotated", rate);

		setTimeout(function(){
			if($("#sidemenu").hasClass("compact")){		// if compact close every secondary menu
				closeAllCompactSecondaryMenus();
			}
		}, rate);

		if($("#sidemenu").hasClass("compact")){
			updateClientSetting("MENU_MODE", "standard");
		}
		else {
			updateClientSetting("MENU_MODE", "icon");
		}

	});

	/**
	 * Open and close second level menus
	 */
	$(".dropdown-handler").click(function () {
		// console.log("dropdown-handler, this = ", this);
		if($(this).hasClass("compact")){
			compactMenuDropdown($(this));
		}
		else {
			standardMenuDropdown($(this))
		}
	});

	/**
	 * Open and close standard (icon + text) second level menus
	 * @param {Object} element - element clicked on to open the menu
	 */
	function standardMenuDropdown(element){
		element.find(".rotate").toggleClass("fa-rotate-90");
		element.next().slideToggle(250);
	}

	/**
	 * Open and close compact (icon only) second level menus
	 * @param {Object} element - element clicked on to open the menu
	 */
	function compactMenuDropdown(element){
		let alreadyOpen = (element.find(".rotate").hasClass("down")) ? true : false;

		// close (slideUp) every secondary menu
		closeAllCompactSecondaryMenus();

		// if it wasn't open, open it now
		if(!alreadyOpen){
			element.find(".rotate").addClass("down");
			element.next().slideToggle(250);
		}
		// reposition the secondary menu next to the where we clicked
		$("#sidemenu.compact .menu-secondary").css({ top: element.position().top + "px" });
	}

	/**
	 * Close all the second level menus
	 */
	function closeAllCompactSecondaryMenus(){
		$("#sidemenu.compact .menu-secondary").slideUp(200);
		$(".dropdown-handler").find(".rotate").removeClass("down");
	}

	$(".dropdown-on-element-handler").click(function(){
		$(this).find(".rotate").toggleClass("down");
  	});

	$(".dropdown-handler-class").click(function() {
		var target = $(this).attr("rel");
		$(this).find(".rotate").toggleClass("down");
		$("." + target).slideToggle(250);
	});

	$(".dropdown-handler-id").click(function () {
		var target = $(this).attr("data-id");
		$(this).find(".rotate").toggleClass("down");
		$("#" + target).slideToggle(250);
	});

	$('body').on('click', '.dropdown-refresh-handler-class', function() {
		var target = $(this).attr("rel");
		$(this).find(".rotate").toggleClass("down");
		$("." + target).slideToggle(250);
	});

	// fix select function focus in chrome/webkit
	if (/webkit/i.test(navigator.userAgent)) {
		HTMLInputElement.prototype.brokenSelectFunction = HTMLInputElement.prototype.select;
		HTMLInputElement.prototype.select = function() {
			setTimeout(function(closureThis) { return function() {
				closureThis.brokenSelectFunction();
			}; }(this), 10);
		};
	}

	// show active section in left-hand menu
	if (g.section != "")
		$("#"+g.section).toggleClass("active",true);

	// select text in inputs with focusSelect class
	$("input[type=text].focusSelect").on("click mouseup tap press",function(){
		this.focus();
		this.setSelectionRange(0, this.value.length);
	});

	// sanitize bad characters from text inputs and textareas
	$("input[type=text],textarea")
		.on("paste",function(ev){
			setTimeout($.proxy(function(){
				$(this).val( $(this).val().replace(/[^\u0000-\u00FF]/g,'') );
			},this),1);
		});

	// add -1 tab index for tooltips
	$(".ToolTip:not([tabindex])").attr("tabindex","-1");

	// remove onclick attributes and add onclicks as event handlers
	$("input[type=button],input[type=reset],input[type=submit],input[type=image],button,a[onclick]").each(function(){
		var o, $t = $(this);
		if ($t.is("[onclick]")) {
			o = $t.attr("onclick");
			$t.attr("data-original-onclick",o)
				.removeAttr("onclick")
				.off("click")
				.on("click",$.proxy(function(){
					if (!$t.is("[data-disabled]") && ( $t.is("a") || !$t.closest("form").is("[data-disabled]") )) {
						//ignore:eval
						eval($t.attr("data-original-onclick"));
					}
				},this));
		}
	});

}


/**
*	Set the Fixed top to account for watermark, error, etc. when loading or changing the side menu or settings
*/
function initPageTop(){
	// Do not adjust the header for external pages
	if($("#header.external")[0]){
		return false;
	}

	let headerHeight = $("#header").height();

	// table-row display needs a set top, but not margin-top
	if($("#contentWrapper").css("display") == "table-row"){
		$("#contentWrapper").css("margin-top", "inherit");
		$("#content").css("padding-top", headerHeight);		// known iPad bug with top: or offset, so we need to force padding
		$("#sidemenu").css("padding-top", headerHeight);
		$("#footer").css("margin-top", headerHeight);	// the footer needs the top margin to be expanded
	}
	// block display needs a margin-top, but not a set top
	else if($("#contentWrapper").css("display") == "block"){
		$("#contentWrapper").css("margin-top", headerHeight);
		$("#content").css("padding-top", "initial");
		$("#sidemenu").css("padding-top", "initial");
		$("#contentWrapper").css("top", "inherit");
		$("#footer").css("top", "inherit");
		$("#footer").css("margin-top", "inherit");
	}
	return true;
}

function updateClientSetting(name,value) {
	// console.log("updateClientSetting(name,value): ", name, value);
	var url = g.baseApiUrl + '/user/client/setting';
  $.ajax({
    type: "POST",
    url: url,
    dataType: "json",
    data: {
			"name": name,
			"value": value
		},
    headers: createBaseApiHeaders(getApiKeyFromStorage(),getApiTokenFromStorage()),
    cache: false,
    success: function(statusData) {
			//console.log("%c Setting client pref: ", "color:green;font-weight:bold;", name + ":" + value);
		},
    error: function(jqXHR, textStatus, errorThrown){
			console.error("Client setting failed to update.");
			throw(errorThrown);
    }
  });
}

function updateClientDisplayPref(value) {
	var url = g.baseApiUrl + '/user/client/display-preference';
  $.ajax({
    type: "POST",
    url: url,
    dataType: "json",
    data: {
			"value": value
		},
    headers: createBaseApiHeaders(getApiKeyFromStorage(),getApiTokenFromStorage()),
    cache: false,
    success: function(statusData) {
			$("#clientDisplayPrefButton").remove();
		},
    error: function(jqXHR, textStatus, errorThrown){
			console.error("Client display setting failed to update.");
    }
  });
}

/**
 * Options Modal from Profile dropdown
 */
$( function() {
  var displayOptionsDialog;
  // old school jq ui dialog box
  displayOptionsDialog = $( "#displayOptions" ).dialog({
    autoOpen: false,
    height: "auto",
    width: "auto",
    modal: true,
    buttons: {
      Close: function() {
        displayOptionsDialog.dialog( "close" );
      }
    }
  });

  $("#displayOptionsOpen").on( "click", function() {
    // console.log("displayOptionsDialog = ", displayOptionsDialog);
    $('#userProfileNavMenu').hide();
    displayOptionsDialog.dialog("open");
  });

});

/**
 * Use the scrollWrapperToFit class in the parent of a tableScrollWrapper element to make the scrolling area re-adjust to the width of the page.
 * Used for scroll areas that take up pretty much the whole screen, not to be used for smaller scroll areas
 */
$(document).ready(function(){
	if($(".scrollWrapperToFit")){
		// console.log('scrollWrapperToFit - body width: ', $("body").width());
		setScrollerWidth();

		$(window).resize(function () {
			setScrollerWidth();
		});

		function setScrollerWidth() {
			// fit to the width of the body tag - 10%
			let adjustedWidth = $("body").width() - ($("body").width() * .1);
			$(".scrollWrapperToFit").css("max-width", adjustedWidth + "px");
		}
	}
});




/* ------------------------------------------ Final Load Functions ------------------------------ */

// Javascript needs to be inserted AFTER all other document ready events
// (all elements on page need to be in final hidden/shown states before this code runs) -->
// this function is called in the footer, after everything else in the document body very last document ready events, must be after others in page
function loadAfterBody() {
	$(document).ready(function(){
		setFormButtonDisableSubmitHandler();
		setAjaxFormsSubmitHandler();
		closeToolTips();
		scrollToHashAnchor();
		resetOverflows(); // must be last
	});
}