/* ------------------------------------------ Init Function ------------------------------ */

function initPaginationText() {
	var $src = $("span.sourcePaginationText"),
			$trg = $("th.targetPaginationText");
	if ($src.length > 0) {
		$trg
			.html($src.html())
			.closest("tr")
			.show()
			.prevUntil("table.dataTable")
			.closest("tr")
			.show(1,function(){
				$trg
					.find("span.paginationShowUser")
					.hide()
					.fadeIn(150);
			});
	}
}

// select all checkboxes (not expenses table)
function initSelectAllTables() {
	$(".dataTable")
		.on("change", ".checkAll", function(){
			var state = $(this).is(":checked");
			var checkboxes = $(this).closest("table").find("tbody input[type=checkbox]");
			checkboxes.prop("checked", state);
			// removed expense table exception  ->  .not(".expenseTable")
	});
}

// HAMBURGER1200: This media query is for the hamburger menu (inner container support)
function initTableScrolling(){
	var headerWidth = $("#header").width();
	var menuWidth = $("#sidemenu").width();
	var menuClosed = $("body#menuClosed").length;

	//  Removed for TIM-1404, but leaving it here when somebody complains that certain tables get too wide on a giant monitor
	/*
	if (headerWidth > 1200 && !menuClosed) {
		$(".tableScrollWrapper").css('max-width', headerWidth - menuWidth - 50 );
	} else {
		// $(".tableScrollWrapper").css('max-width', headerWidth - 25 );	// not needed but keep if needed for final tweaking
	}
	*/
	$(".tableScrollWrapper").addClass("table-print-correction");	// because FF resizes every time you print?!?!
}


$( window ).resize(function() {
	initTableScrolling();
});


/* ------------------------------------------ Main Function ------------------------------ */

// Main Hierarchy Function
function setHierarchy() {

	// sets vars
	var $sel = $("#employeeDataTable .hierarchy");
	var selN = $sel.length;
	var oneRootExpanded = false;
	var toggleClose = localStorage.getItem("hierIDs");

	// Initialization & iterate over each
	$sel.each( function( n ) {
		var $this = $(this);
		var depth = 0;

		$main = $this.find(".hDiv");
		$mainNext = $this.next( ".hierarchy" ).find( ".hDiv" );
		$control = $this.find( ".hierarchyControl > div" );
		depth = $main.data( "obj" ).hDepth;

		if ( depth == 0 && $main.data( "obj" ).hExpanded )
			oneRootExpanded = true;

		if ( $control.length > 0 ) {
			if (
				( $mainNext.length > 0 && $mainNext.data( "obj" ).hDepth <= depth ) || n + 1 == selN ) {
				if ( $control.is( ".minusAdmin, .plusAdmin" ) )
					$control.removeClass( "minusAdmin plusAdmin").toggleClass( "blankAdmin", true );
				if ( $control.is( ".minusSuper, .plusSuper" ) )
					$control.removeClass( "minusSuper plusSuper").toggleClass( "blankSuper", true );
				if ( $control.is( ".minusPrimary, .plusPrimary" ) )
					$control.removeClass( "minusPrimary plusPrimary" ).toggleClass( "blankPrimary", true );
			}
		}
	});

	// Individual row clicks
	$( "#employeeDataTable" ).on( "click", ".hierarchyControl",function(){
		var $row = $( this ).parents( ".hierarchy" );
		traverseRow( $row );
		putContracted();
	});

	// All row clicks
	$( "#hierarchyAll" ).on( "click", function() {
		toggleVal = $(this).is( ".hierarchyAllMinus" );

		// (a) closing and (b) opening
		if ( toggleVal ) {
			hierarchyAll( 0 );

			$( this )
				.toggleClass( "hierarchyAllMinus", false )
				.toggleClass( "hierarchyAllPlus", true );

		} else {
			hierarchyAll( 1 );

			$(this)
				.toggleClass("hierarchyAllPlus",false)
				.toggleClass("hierarchyAllMinus",true)

			}
	});

	// Check root default
	if ( !oneRootExpanded )
		$( "#hierarchyAll" )
			.toggleClass( "hierarchyAllMinus", false )
			.toggleClass( "hierarchyAllPlus", true );
}

/* ------------------------------------------ Extension ------------------------------ */

// traverses employee row-depth
$.fn.extend({
	traverseEmployees: function( depth, expanded ) {
		$(this).nextAll().each( function() {
			if ( $(this).find(".hDiv").length > 0) {

				var $row = $(this).find(".hDiv");
				var thisDepth = $row.data( "obj" ).hDepth;
				var isControl = ( $row.find(".hierarchyControl").length > 0 );

				// cancel out
				if ( thisDepth <= depth ) return false; // break loop

				// keep going
				if ( expanded ) {
					if ( thisDepth == depth + 1 ) {
						$( this ).show();
						if (isControl)
							$( this ).traverseEmployees( thisDepth, $row.data( "obj" ).hExpanded );
					}
				} else {
					$( this ).hide();
				}
			}
		});
	}
});


/* ------------------------------------------ Single Use Functions ------------------------------ */
// Main Homepage
// Expands and closes all information
function hierarchyAll( expanded ) {
	// set icons, expanded argument
	$( "#employeeDataTable .hierarchyControl" )
		.each( function() {
			$parent = $( this ).parents( ".hDiv" );
			hierarchyNodeSet( $parent, expanded );
		});

	// traverse employees for each
	$("#employeeDataTable tr.hierarchy:has( .hierarchyControl )")
		.each( function() {
			if (
				$( this ).find( ".hDiv" ).data( "obj" ).hDepth == 0)
				$( this ).traverseEmployees( 0, expanded );
			});

	// row color & row state
	setHierarchyAlternateRows();
	putContracted();
}

/**
 * 	Adjusts the clock in/out cell on the Dashboard to accomodate different languages
 *
 */
$(document).ready(function(){
  let label = $(".ep_time");	// grab all the Clock In/Out cells
  var longest = 0;
  label.each(function (index) {
    let current = label[index].textContent.trim();	// Translated Clock In/Out text
    longest = (current.length > longest) ? current.length : longest;
  });
  $(".ep_time").css("width", (longest + 1) + "ch");		// add a character for spacing
});


/* ------------------------------------------ Multiple Use Functions ------------------------------ */

// Traverses Rows & Sets Icons
// sets plus & minus icons
function hierarchyNodeSet( hDiv, expanded ) {
	if ( expanded ) {
		hDiv.data("obj").hExpanded = true;
		hDiv.find(".plusPrimary").removeClass("plusPrimary").addClass("minusPrimary");
		hDiv.find(".plusAdmin").removeClass("plusAdmin").addClass("minusAdmin");
		hDiv.find(".plusSuper").removeClass("plusSuper").addClass("minusSuper");
	}
	else {
		hDiv.data("obj").hExpanded = false;
		hDiv.find(".minusPrimary").removeClass("minusPrimary").addClass("plusPrimary");
		hDiv.find(".minusAdmin").removeClass("minusAdmin").addClass("plusAdmin");
		hDiv.find(".minusSuper").removeClass("minusSuper").addClass("plusSuper");
	}
}

// Traverses Rows & Provides State
// opens & closes rows
function traverseRow( tr ) {
	$this = tr.find( ".hDiv" );
	var depth = $this.data( "obj" ).hDepth;
	var expanded = $this.data( "obj" ).hExpanded;

	// false = open; true = closed
	expanded ? hierarchyNodeSet( $this, false ) : hierarchyNodeSet( $this, true);

	// employee depth: when expanded is false (open)
	tr.traverseEmployees( depth, !expanded );

	// colorize
	setHierarchyAlternateRows();
}

// Sets Row Colors
// Colorize alternate rows
function setHierarchyAlternateRows() {
	$( "#employeeDataTable .hierarchy:visible" )
		.filter( ":odd" ).toggleClass( 'even', true ).end()
		.filter( ":even" ).toggleClass( 'even', false );
}

// Gets Data
// Saves data from cookie and sets table
function getContracted() {
	var changed = false;
	var ids = $.cookie("hierIDs");
	for (n=0;n<ids.length;n++) {
		if ($("#employeeDataTable .hierarchy#userid"+ids[n]).length > 0) {
			$("#employeeDataTable .hierarchy#userid"+ids[n]+" div.hierarchyControl").each(function(){
				$.tr = $(this).parents(".hierarchy");
				traverseRow($.tr);
			});
		}
		else
			changed = true;
	}
	if (changed) putContracted();
}

// Saves Data
// Saves data from opened & closed rows into localstorage
function putContracted() {
	var ids = new Array();
	$( "#employeeDataTable .hierarchyControl" ).each( function(){
		$this = $(this).parents( ".hierarchy" );
		if ( $this.find( ".hDiv" ).data( "obj" ).hExpanded == false ) {
			ids.push( parseInt( $this.attr("id" ).replace( "userid","" ), 10 ));
		}
	});

	// set localStorage
	localStorage.setItem( "hierIDs", ids.toString() );
}


/* ------------------------------------------ Search Functions ------------------------------ */
// User pull-down menu trigger
// #1 Menu click starts here
// Returns: user (id)
function setUserAutocomplete() {
	$( "select[name=UserSelect]" )
		.trigger( "focus" )
		.on( "change", function () {
			var sel = $( this ).find( "option:selected" ).val();
			if (sel != "") {
				userAutocompleteTrigger( sel );
				$( this ).trigger( "selected" );
			}
		});
}

function setUserAutocompleteKiosk() {
	$( "select[name=UserSelect]" )
		.on( "change", function () {
			var selectedID = $( this ).find( "option:selected" ).val();
			if (selectedID != "") {
				$(".kioskDashboard tr").removeClass("selectedUser");
				$("#userid"+selectedID).addClass("selectedUser");
				document.getElementById("userid"+selectedID).scrollIntoView({ behavior: "smooth", block: "end" });
			}
		});
}

// Originates from setUserAutocomplete
// Submits to hierarchyExpandUserID
function userAutocompleteTrigger( id ) {
	$( ".hierarchy" ).toggleClass( "hover", false );
	$target = $(".hierarchy[id = userid" + id + "]");

	if ( $target.length > 0) {

		// sends the user (id), returns select
		hierarchyExpandUserID( id, function() {
			$target.toggleClass( "hover", true );
			$.scrollTo( $target, 100, {
				offset: -100,
				callback: function() {
					// TODO: Find this
					// $target.floatBorder();
					$("input[name=NewUserSelect]").trigger("select");
				}
			});
		});
	}
}

// Originates from userAutocompleteTrigger
// Sets node and employee information
function hierarchyExpandUserID( id, callback ) {
	var $user = $("#employeeDataTable .hierarchy#userid" + id);
	var depth = $user.find(".hDiv").data( "obj" ).hDepth;

	$user.next().prevAll( ".hierarchy" ).each( function(){
		var isControl = $( this ).find( ".hierarchyControl" ).length > 0;
		var itemDepth = $( this ).find( ".hDiv" ).data( "obj" ).hDepth;

		// sets plus-minus icons
		if ( itemDepth <= depth ) {
			depth-- ;
			if ( isControl ) hierarchyNodeSet( $(this).find( ".hDiv" ), true);
		}
		// sets employee traversal
		if (depth == -1) {
			$( this ).traverseEmployees( 0, true );
			return false;
		}
	});

	// row color & row state
	setHierarchyAlternateRows();
	putContracted();

	if ( typeof callback === "function" )  callback();
}

/* ------------------------------------------ General Table Functions ------------------------------ */
function clearDefaultsForRow(row,anim) {
	if (row !== "default") {
		var $targetRow = $("tr.assignmentRow[data-row="+row+"]"),
				$defaultRow = $("tr.assignmentRow[data-row=default]");
		if ($targetRow.find(":checkbox:checked").length > 0)
			$targetRow.find(":radio").prop("disabled",false).closest("td,th").removeClass("disabled");
		else {
			$targetRow.find(":radio:checked:not(:disabled)").each(function(){
				var radioName = $(this).attr("name");
				$defaultRow.find(":radio[name="+radioName+"]").prop("checked",true);
				if (anim) {
					$targetRow.find(":radio[name="+radioName+"]").closest("td")
						.effect('transfer',{
							to: $defaultRow.find(":radio[name="+radioName+"]").closest("td"),
							className: 'ui-effects-transfer',
							easing:'easeInSine'
						},500,function(){
							$defaultRow.find(":radio[name="+radioName+"]").closest("td")
								.floatBorder();
						});
				}
			});
			if ($targetRow.find(":checkbox:checked").length == 0)
				$targetRow.find(":radio:not(:checked)").prop("disabled",true).closest("td,th").toggleClass("disabled",true);
		}
	}
}

/* ------------------------------------------ Ready Function ------------------------------ */
$(document).ready(function () {


	/* ------------------------------------------ Global Visual Functions ------------------------------ */
	$.fn.extend({

		alternateRowsByDate: function(callback) {
			var lastDate = "N/A",
					alt = true;
			$(this).find("tbody > tr").each(function(i){
				$(this).find("td.date")
					.wrapInner("<span />")
					.find("span")
					.addClass("dateSpan");
				var tr = $(this),
						dateSpan = tr.find("td.date > span.dateSpan"),
						skip = tr.is(".skip"),
						prevSkip = tr.prev().is(".skip");
				if (dateSpan.length > 0) {
					var thisDate = dateSpan.text(),
							next = tr.nextUntil(":not(.notes)"),
							isBar = tr.is(".bar");
					if (thisDate != lastDate || isBar) {
						alt = !alt;
						if (i != 0)
							tr.find("td").css({"border-top-width":"1px","border-top-color":"#999"});
					}
					else if (!prevSkip && !skip) {
						dateSpan
							.toggleClass("hideOnCollapse",true)
							.addClass("noDisplay")
							.parent()
							.prev()
							.find(".toggleDateAbbrv")
							.addClass("hideOnCollapse noDisplay");
							//.wrapInner('<span class="hideOnCollapse noDisplay" />');
					}
					lastDate = thisDate;
					tr.toggleClass("even",alt).next("tr.notes").toggleClass("even",alt);
				}
			});
			if (typeof callback === "function")	callback();
		},

		showChangesInTable: function() {
			$(this).each(function(){
				var $arrowCode = $("<div />").attr("class","rel"),
						rows = $(this).children("tbody").children("tr").length,
						columns = 0,
						previousRowArray = new Array();
				$("<div />")
					.attr("class","historyArrowDiv")
					.append(
						$("<div>")
							.attr({
								class: "fal fa-arrow-up font-teal fa-lg"
							})
						.append(
							$("</div>"))
						)
					.appendTo($arrowCode);
				$(this).children("tbody").children("tr").each(function(){
					columns = Math.max(columns,$(this).children("td").length);
				});
				for (var r=rows-1;r>=0;r--) {
					tr = $(this).children("tbody").children("tr").eq(r);
					if (tr.children("td").length == columns && !tr.is(".requestRow")) {
						for (c=columns-1;c>=0;c--) {
							cell = tr.children('td').eq(c);
							if (!cell.is(".ignore")) {
								var cellHTML = cell.clone().find(".ignore").remove().end().text();
								if (r != rows-1 && cellHTML != previousRowArray[c]) {
									if ($.trim(cell.html()) == "")
										cell.html("&nbsp;");
									cell
										.toggleClass("historyCellChange",true)
										.append($arrowCode.clone());
								}
								previousRowArray[c] = cellHTML;
							}
						}
					}
				}
			});
		}

	});


	// This (from old Ford codebase) adds a select all checkboxes in a report button to reports pages showing checkboxes
	var html = "";
	if ($("table.dataTable:has(input.checkAll:checkbox)").length > 1) {
		html += '<div style="margin:0 0 5px 3px;" id="selectAllDataTableChecksWrapper" class="noPrint">';
		if ($("table.dataTable.expenseTable").length > 1)
			html += '<div class="rel" style="text-align:right;padding:0;margin:0;">';
		html += '<input type="checkbox" id="selectAllDataTableChecks" /><label for="selectAllDataTableChecks"><strong>' + getTranslation('Content.Checkbox.SelectDeselectAll') + '</strong></label>';
		if ($("table.dataTable.expenseTable").length > 1)
			html += '<div class="largeText" id="allExpenseTotalsWrapper" style="display:none;font-weight:bold;margin-top:5px;">' + getTranslation('Content.Label.AllSelectedTotal') + '<span class="largeText"></span></div></div>';
		html += '</div>';
		$("table.dataTable:has(th input.checkAll:checkbox)").first().each(function(){
			if ($(this).prev("div").is(".tabs")) {
				$(this).prev("div.tabs").before(html);
				$("#allExpenseTotalsWrapper")
					.toggleClass("abs",true)
					.css({
						top: "1.75em",
						right: 0,
						marginTop: 0
					});
			}
			else {
				$(this).before(html);
				$("#allExpenseTotalsWrapper").remove();
			}
			if ($(this).find("th input.checkAll").first().prop("checked")){
				$("#selectAllDataTableChecks").prop("checked",true);
			}
			if ($(this).find("th:eq(1)").find("input.checkAll:checkbox").length == 0){
				$("#selectAllDataTableChecksWrapper").css("text-align","right");
			}
		});
		$("#selectAllDataTableChecks").on("click",function(){
			$("table.dataTable th input.checkAll:checkbox:not(:disabled)")
				.prop("checked",$(this).prop("checked"))
				.trigger("change");
		});
	}


});