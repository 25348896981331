$(document).ready(function(){

	$(".ts-modal-launch").on("click", function(e){
		$(".close-modal").remove();
		$(".modal-title-bar, .modal-title").remove();
		setupTSModal($(this).data());
	}); // EO on launch
});

/**
 * Launch a TS modal built in JavaScript (otherwise it's setup in CF)
 * @param {Object} dataTags - JS Oject representation of data tags in the form. For example:
 * 		{for: 'breakModal', title: 'Break', content: 'and so on....' }
 */
function launchModalNew(dataTags){
	setupTSModal(dataTags);
}

/**
 * Create a TS Modal in JS (most are setup in CF)
 */
class TSModal {
	for;
	setupObject = {};

	/**
	 * param {String} idFor - id of targeted modal
	 * param {Object} settings - in the form of {for: 'breakModal', title: 'Break', content: 'and so on....' }
	 */
	constructor(idFor, settings = {}){
		this.for = idFor;
		this.setupObject.for = idFor;
		if(settings.title){
			this.setupObject.title = settings.title;
		}
		if(settings.content){
			this.setupObject.content = settings.content;
		}
		this.attachToPage();
		
	}
	/**
	 * attaches to modal to the body element of the page
	 */
	attachToPage(){
		let baseModal = '<div id="' + this.for + '" class="ts-modal ts-modal-container noPrint">';
		baseModal += '<div class="modal-content">' + this.setupObject.content + '</div>';
		baseModal += '</div>';
		$("body").append(baseModal);
	}
	/**
	 * launches the modal simply using the standard setup
	 */
	launch() {
		setupTSModal(this.setupObject); // { for: this.for, title: "object style?" });
	}
}


/**
 * Setup the TS modal from either JS or CF
 * @param {Object} dataTags - in the form of {for: 'breakModal', title: 'Break', content: 'and so on....' }
 */
function setupTSModal(dataTags){
	// console.log("%c setupTSModal  with data tags:     ", "background-color:black;color:lightgreen;", dataTags);
	let target = $("#" + dataTags.for);	// <... data-for="id_goes_here" ...> is required
	target.show();

	$(".modal-title-bar").remove();
	$(".modal-content").prepend('<div class="modal-title-bar"></div>');

	// Add a title, optional data-title=""
	if(dataTags.title){
		$(".modal-title-bar").append('<div class="modal-title">' + dataTags.title + '</div>');
	}
	// Add the support link to the title bar if defined, optional data-suport-link=""
	if(dataTags.supportLink){
	 	$(".modal-title-bar").append('<div class="support-link-modal"><a href="' + dataTags.supportLink + '" target="_blank"><i class="fad fa-life-ring"></i></a></div>');
	}

	// X to close the modal in upper right corner, optional data-close-corner="true/false"
	let useCloseCorner = true; // display by default unless data-close-corner="false"
	if(typeof dataTags.closeCorner !== "undefined" && !dataTags.closeCorner){
		useCloseCorner = false;
	}

	// Add X button in upper right corner
	if(useCloseCorner){
		$(".modal-title-bar").append('<span class="close close-modal"><i class="fas fa-x font-gray"></i></span>');
	}

	$(".modal-body").remove();
	$(".modal-content").append('<div class="modal-body"></div>');	// for dynamic content 

	let useCloseButton = true; // overriden by data-close-button="false"
	if(typeof dataTags.closeButton !== "undefined" && !dataTags.closeButton){
		useCloseButton = false;
	}

	// Add "Close" button under modal content
	if(useCloseButton){
		if($(".button-bar").length){
			$(".button-bar").remove();
		}
		$(".modal-content").append('<div class="button-bar"><button class="close-modal textBtn">' + getTranslation("Content.Btn.Close") + '</button></div>');
	}

	// Close modal on X corner button or Close button
	//     unless overriden by data-close-corner="false" or data-close-button="false"
	$(".close-modal").on("click", function(){
		closeModal();
	});

	// Close modal on user defined close button
	// Any clickable element with class="custom-close-modal" will close without further action
	$(".custom-close-modal").on("click", function(){
		closeModal();
	});

	// Close modal with "esc" key, unless overriden by data-close-esc="false"
	if(typeof dataTags.closeEsc === "undefined" || dataTags.closeEsc){
		$(document).keyup(function(e){
			if(e.key == "Escape"){
				closeModal();
			}
		});
	}

	// Local clean up and close the modal
	function closeModal(){
		$(".close-modal").remove();
		$(".modal-title").remove();
		target.hide();
	}
}

/**
 * "Global" clean up and close all ts modals: hide modal and remove actions
 * 	@param {boolean} reloadPage - reload the underlying page, default: false 
 */
function closeTSModal(reloadPage = false){
	$(".close-modal").remove();
	$(".modal-title").remove();
	$(".ts-modal").hide();
	if(reloadPage){
		location.reload();
	}
}

/**
 * "Global" close a specific modal
 * 	@param {String} id - ID of modal to close 
 * 	@param {boolean} reloadPage - reload the underlying page, default: false 
 */
function closeTSModalById(id, reloadPage = false){
	// console.log("closeTSModalById()", id);
	$("#"+id).hide();
}


/*

HTML Template for using the TS Modal:

<button type="button" class="ts-modal-launch" data-for="id_of_your_modal_goes_here" data-title="Title Goes Here" data-close-corner="false" data-close-esc="false" data-close-button="false" data-support-link="http://ts.whatever">Launch Modal</button>
<div id="id_of_your_modal_goes_here" class="ts-modal ts-modal-container noPrint">
	<div class="modal-content">
		<p>Whatever content.... </p>
		<div class="buttons"><!--- optional --->
			<button onclick="$('#id_of_your_modal_goes_here').hide()">You can make your own close button if you need to by hiding inline</button>
			<a href="##" class="custom-close-modal">Close me link</a><!--- or by setting class="custom-close-modal" in any clickable element --->
		</div>
	</div>
</div>

--------------------------------------------------------

JS Modal:

let myModal = new TSModal("myModal", { title: "This is my modal", content: '<p>Content goes <b>here</b> whatever you want goes here</p>' });
myModal.launch();

*/

